.footer {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #717171;
    padding: 15px 0;
}

.footer ul li {
    list-style: none;
    display: inline-block;
    margin-left: 20px;
}
.footer .social-icons {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 16px;
  }
  
  .footer  .social-icons li {
    margin: 0 2px; /* Adjust margin as needed */
  }
  
  .footer .social-icons li a {
    display: block;
    width: 30px; /* Adjust icon size as needed */
    height: 30px;
    border-radius: 50%; /* For circular icons */
    background-color: #d2d0d0; /* Adjust background color as needed */
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333; /* Adjust icon color as needed */
    text-decoration: none;
  }

  .footer .social-icons li a:hover {
    background-color: rgb(255, 255, 255); /* Non-bright red color */
    transition: background-color 0.3s ease-in-out; /* Animated transition */
  } 

@media (max-width: 600px) {
    .footer {
        display: block;
        text-align: center;

    }

    .footer ul li {
        margin: 10px;

    }

    .footer ul {
        margin-top: 10px;
    }
}