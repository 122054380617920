@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  position: relative;

}

body {
  background-color: #FFFAFB;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  padding-right: 10%;
  padding-left: 10%;
}

.btn {
  background: #ab2824;
  color: #FFFAFB;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  justify-items: center;
  justify-content: center;
}

.btn img {
  width: 20px;
  margin-left: 10px;
  margin-top: 5px;
}

.btn.dark-btn {
  background: #ab2824;
  color: #fff;
}

.serv-img {
  height: 130px;
  width: 250px;
  border-radius: 20px;
}

/* media query */
@media (max-width:1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .btn {
    padding: 14px 22px;
  }
}