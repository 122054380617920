.navbar {
  background-color: #f2f2f2;
  padding: 10px;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 5px;
}

.nav-link:hover {
  background-color: #ddd;
}

hr {

  width: 400px;
  margin: auto;
}