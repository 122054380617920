.title {
    text-align: center;
    color: #0e0e0e;
    font-size: 20px;

    text-transform: none;
    margin: 70px 0 -20px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.title.visible {
    opacity: 1;
}

.title h2 {
    font-size: 32px;
    font-weight: 700;
    color: #323231;
    margin-top: 5px;
    margin-bottom: 20px;
    text-transform: uppercase;
}