.servieces {
    margin: 0px auto;
    padding: 0 80px;
    position: relative;


}

.next-btn,
.back-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 15px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    background: #ab2824;
}

.back-btn {
    right: auto;
    left: 0;

}

.slider {
    overflow: hidden;

}

.slider ul {
    display: flex;
    width: 280%;
    overflow-x: hidden;
    transition: 0.5s;

}

.slider ul li {
    list-style: none;
    width: 30%;
    padding: 10px;
}

.slide-serv {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    color: #676767;
    line-height: 1.4;
    border: -4px solid #2b2a29;
    height: 90%;
    width: 100%;

}

.serv-img {
    width: 100%;
    border-radius: 10%;
}

.serv-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 15px;
}


.serv-info h3 {
    color: #bc2622;
}