.solar-power {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding-top: 10px;
    margin: 80px auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.solar-pow img {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    display: block;
}

.solar-pow {
    flex-basis: calc(33.33% - 20px);
    margin-bottom: 40px;
    position: relative;
}

.caption {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(188, 38, 34, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    padding-top: 70%;
    transition: 0.4s;
    padding-left: 10px;
}


.caption img {
    width: 50px;
    margin-bottom: 10px;
}

.solar-pow:hover .caption {
    opacity: 1;
    padding-top: 0;
}

.solar-btn {
    margin: 50px auto;
}


.solar-power-expanded {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;
}

/* @media Query */
@media(max-width:650px) {
    .solar-power {
        flex-direction: column;

    }

    .solar-pow {
        flex-basis: 100%;
        margin: 20px;

    }

    .solar-pow img {
        width: 48%;
        margin-bottom: 25px;
    }

}