.staff {
    margin: 80px auto;
    padding: 0 80px;
    position: relative;
}

.next-btn,
.back-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 15px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    background: #2b2a29;
}

.back-btn {
    right: auto;
    left: 0;
}

.slider {
    overflow: hidden;
}

.slider ul {
    display: flex;
    width: 300%;
    overflow-x: hidden;
    transition: 0.5s;

}

.slider ul li {
    list-style: none;
    width: 30%;
    padding: 20px;
}

.slide {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 10px;
    color: #676767;
    line-height: 1.4;
}

.slide img {
    width: 100px;
    border-radius: 10%;
    margin-right: 10px;
    border: 4px solid #bc2622;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 15px;
}

.user-info h3 {
    color: #bc2622;
}

/* @media */
@media (max-width: 800px) {
    .staff {
        padding: 0 40px;
    }

    .next-btn,
    .back-btn {
        padding: 20px;
        width: 35px;
    }

    .staff ul li {
        padding: 10px;
    }

    .slide {
        padding: 20px;
    }

    .slide img {
        width: 50px;
        border: 3px solid #2b2a29;
    }
}

@media (max-width: 600px) {
    .user-info {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .user-info h3 {
        font-size: 16px;

    }

    .slider p {
        font-size: 12px;
    }
}