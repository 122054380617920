.contact {
    margin: 80px auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-col {
    flex-basis: 48%;
    color: #676767;
}

.contact-col h3 {
    color: #000f38;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-col h3 img {
    width: 35px;
    margin-left: 10px;
}

.contact-col p {
    max-width: 450px;
    list-style: circle;
}

.contact-col ul li {
    display: flex;
    align-items: center;
    margin: 20px auto;
}

.contact-col ul li img {
    width: 20px;
    margin-right: 10px;
}

.contact form input,
.contact form textarea {
    display: block;
    width: 100%;
    background: #ebecfe;
    padding: 15px;
    border: 0;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;
}

.contact-col span {
    display: block;
    margin: 20px 0;
}

/* @media */
@media (max-width: 700px) {
    .contact {
        display: block;

    }

    .contact-col {
        padding: 20px;
    }
}