.hero {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(188, 38, 34, 0.3), rgba(58, 58, 60, 0.7)), url('../../../assets/hero3.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-text {
    text-align: center;
    max-width: 800px;
}

.hero-text h1 {
    font-size: 40px;
    font-weight: 600;
}

.hero-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

/* @media Query */
@media(max-width:850px) {
    .hero-text h1 {
        font-size: 40px;

    }

}

@media(max-width:650px) {
    .hero-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;

    }

    .hero-text p {
        font-size: 14px;
        margin: 15px auto 30px;
    }

}