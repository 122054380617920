.about {
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.about-left {
    flex-basis: 40%;
    position: relative;
}

.about-right {
    flex-basis: 56%;
    margin-left: 30px;
}

.about-img {
    width: 100%;
    border-radius: 10px;
}

.play-icon {
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.about h3 {
    font-weight: 600;
    font-size: 16px;
    color: #383838
}

.about h2 {
    font-size: 35px;
    color: #383838;
    margin: 10px 0px;
    max-width: 400px;
}

.about p {
    color: #676767;
    margin-bottom: 15px;
}

/* @media  */
@media (max-width:800px) {
    .about {
        flex-direction: column;
    }

    .about-left {
        flex-basis: 100%;
        margin: 20px;
        order: 2;
    }

    .about-right {
        flex-basis: 100%;
    }


}